import React, { useState, useEffect } from 'react';
import '../../Captcha.css';
import { Link, useParams } from 'react-router-dom';
import { login_student_with_captcha } from '../../apis/Signup';
const CaptchaComponent = () => {
    const [captcha, setCaptcha] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showCaptcha, setShowCaptcha] = useState(true);
    const [showDone, setShowDone] = useState(false);
    const token = useParams();
    // Function to generate a random CAPTCHA
    const generateCAPTCHA = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const CAPTCHALength = 6;
        let randomCAPTCHA = '';
        for (let i = 0; i < CAPTCHALength; i++) {
            const randomNumber = Math.floor(Math.random() * chars.length);
            randomCAPTCHA += chars.substring(randomNumber, randomNumber + 1);
        }
        setCaptcha(randomCAPTCHA);
        setErrorMessage('');
        setSuccessMessage('');
        setInputValue('');
    };

    useEffect(() => {

    }, [captcha, inputValue, errorMessage]);

    // Handle form submission
    const handleSubmit = () => {
        if (inputValue === '') {
            setErrorMessage("This Field Can't Be Empty!");
            return;
        } else if (inputValue !== captcha) {
            setErrorMessage("Try Again!");
            return;
        } else {
            setErrorMessage('');
            setSuccessMessage('CAPTCHA Verified!');
            setShowDone(true);
            setShowCaptcha(false);
            login_student_with_captcha(token?.token)
        }
    };
    useEffect(() => {
        generateCAPTCHA();
    }, []);
    return (
        <div className="box">
            {showCaptcha && (
                <div className="box-CAPTCHA" id="box-CAPTCHA">
                    <div className='captcha-main'>
                        <div className="CAPTCHA" id="CAPTCHA">
                            {captcha}
                        </div>
                        <button className="reload" onClick={generateCAPTCHA}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 11C20.4 11 20 11.4 20 12C20 14.9 18.5 17.5 16 18.9C12.2 21.1 7.3 19.8 5.1 16C2.9 12.2 4.2 7.3 8 5.1C11.3 3.2 15.3 3.9 17.8 6.5H15.4C14.8 6.5 14.4 6.9 14.4 7.5C14.4 8.1 14.8 8.5 15.4 8.5H19.9C20.5 8.5 20.9 8.1 20.9 7.5V3C20.9 2.4 20.5 2 19.9 2C19.3 2 18.9 2.4 18.9 3V4.8C17 3 14.6 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 11.4 21.6 11 21 11Z" fill="black" />
                            </svg>
                        </button>
                    </div>
                    <input
                        type="text"
                        id="input"
                        placeholder="Type CAPTCHA Here"
                        maxLength="6"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    {errorMessage && <div id="wrong" style={{ marginLeft: '10px', color: '#f00' }}>{errorMessage}</div>}
                    {successMessage && <div id="done" style={{ marginLeft: '10px', color: '#080' }}>{successMessage}</div>}
                    <button className="submit" onClick={() => handleSubmit()}>Submit</button>
                </div>
            )}
            {showDone && (
                <div className="box-done" id="box-done">
                    <div className='done-sec'>
                        <h1><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 2.00003L6 14L0.5 8.50003L1.91 7.09003L6 11.17L16.59 0.590027L18 2.00003Z" fill="black" />
                        </svg>Done</h1>
                        <Link to="/dashboard" >Dashboard</Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CaptchaComponent;
