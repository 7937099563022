import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getquestionList } from '../../../apis/My-e-Test/ClassTest';
import jsPDF from 'jspdf';
import { add_Questionaire } from '../../../apis/My-e-Test/ClassTest';
import quizvictor from '../../../assets/QuizeImages/quiz-vector.svg';
import tick from '../../../assets/QuizeImages/tick.png'
import winner from '../../../assets/QuizeImages/winner.svg';
import logo from '../../../assets/QuizeImages/cslogo.png'
const Questionaire = () => {
    const id = useParams();
    const [questions, setQuestions] = useState([]);
    const [response, setResponse] = useState([{ question: "", answer: "" }]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [correct, setCorrect] = useState(0);
    const [inProgress, setInProgress] = useState(true);
    const [count, setCount] = useState(0)
    const [running, setRunning] = useState(false)
    const [show, setShow] = useState(false)
    const [time, setTime] = useState({})
    const [questionID, setQuestionID] = useState({})
    const timerRef = useRef();
    const Questionref = useRef();
    useEffect(() => {
        // console.log('Response updated:', response);
    }, [response]);
// console.log('correct / questions.length',correct , questions.length);
    const handleCountdown = (seconds) => {
        setCount(seconds);
        setRunning(true);
    };
    const Clock = ({ time }) => (
        <div className='logo-header-sec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='header-main-sec'>
                        <img src={logo} />
                        <h2>Timescale:{`${String(Math.floor(time / 60)).padStart(2, '0')}:${String(
                        time % 60
                        ).padStart(2, '0')}`}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
    const Input = ({ onSetCountdown }) => {
        const startimer = () => {
            const strSeconds = `${time}`;
            if (strSeconds.match(/[0-9]/)) {
                onSetCountdown(parseInt(strSeconds, 10));
            }
        };
        return (
            !show ?
                <div className="subscribe-sec-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="header-heading inner">
                                <h2>Class Test</h2>
                                </div>
                            </div>

                            <div className="col-12 col-md-12">
                                <div className="quiz-content">
                                    <h2>Things to know before you start:</h2>
                                    <ul>
                                        <li><img src={tick} /> In each quiz, you are required to answer 5 questions.</li>
                                        <li><img src={tick} /> You will have 1 minutes for each question. If you fail to complete a question in given time, your answer will be considered incorrect.</li>
                                    </ul>
                                    <a onClick={() => { startimer(); setShow(true) }}>Let Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={quizvictor} />
                </div>
                : ''
        );
    };
    useEffect(() => {
        if (running) {
            timerRef.current = setInterval(() => setCount((prevCount) => Math.max(prevCount - 1, 0)), 1000);
            return () => clearInterval(timerRef.current);
        }
    }, [running]);

    let generatePDF = () => {
        const doc = new jsPDF();
        if (questions) {
            const propertiesToDelete = ["answer"];
            const tableData = questions.map((record) => {
                const filteredRecord = Object.keys(record)?.filter((key) => !propertiesToDelete?.includes(key))?.reduce((obj, key) => {
                    obj[key] = record[key]; return obj;
                }, {});
                return Object.values(filteredRecord);
            });
            const tableHeaders = Object.keys(questions[0]);
            delete questions[0].answer
            doc.autoTable({
                columnStyles: {
                    6: { cellWidth: 25 },
                    7: { cellWidth: 30 },
                },
                head: [tableHeaders],
                body: tableData,
            });
            doc.output('dataurlnewwindow');
        }
    }
    useEffect(() => {
        async function get_data() {
            const profile = await getquestionList(id.id);
            setQuestions(profile?.data?.question_data);
            setTime(profile?.data?.time * 60);
            setQuestionID(profile?.data?.q_id)

        }
        get_data();
    }, []);
    console.log('id',questionID);
    const radioRef = useRef(null);
    const updateAnswer = (e, question, option) => {
        const answerValue = e.target.value;
        setResponse([...response, { question: question, answer: option }])
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestion].answerCorrect = JSON.parse(answerValue);
        setQuestions(updatedQuestions);
        updatedQuestions[currentQuestion].checked = true;
    };
    const nextQuestion = () => {
        setCurrentQuestion(currentQuestion + 1);
        radioRef.current.reset();
    };
    // const previousQuestion = () => {
    //     setCurrentQuestion(currentQuestion - 1);
    //     radioRef.current.reset();
    // };
    const getResults = async () => {
        const correctCount = questions.reduce((count, item) => (item.answerCorrect ? count + 1 : count), 0);
        setCorrect(correctCount);
        setInProgress(false);
        const uniqueArray = response.reduce((unique, item) => {
            const questionWithoutNewlines = item.question.replace(/\r?\n|\r/g, " ");
            if (!unique.some((obj) => obj.question === questionWithoutNewlines)) {
                item.question = questionWithoutNewlines; // Update the question property
                unique.push(item);
            }
            return unique;
        }, []);
        // const uniqueArray = response.reduce((unique, item) => {
        //     if (!unique.some((obj) => obj.question === item.question)) {
        //         console.log('item',item.replace(/\r?\n|\r/g, " "))
        //         unique.push(item);
        //     }
        //     return unique;
        // }, []);
        uniqueArray.shift()
        let payload = {
            request: uniqueArray,
            // c_id: id.id,
            q_id:questionID,
            correct:correctCount
        }
        await add_Questionaire(payload);
    };
    const currentQuestionData = questions[currentQuestion];
    return (
        <div className='subscribe-sec'>
            <div class="col-12 col-md-12">
                <div class="header-heading">
                    <Clock time={count} />
                    <Input onSetCountdown={handleCountdown} />
                </div>
            </div>
            {
                count !== 0 ?
                    <>
                        {
                            show ?
                                <>
                                    <div class="subscribe-sec-inner">
                                        <form ref={radioRef}>
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12 col-md-12">
                                                        <div class="quiz-content questionair-sec">
                                                            <div class="question-sec">
                                                                <h2>Question {currentQuestion + 1} / {questions.length}</h2>
                                                                <div class="progress">
                                                                    <div class="progress-bar" role="progressbar"
                                                                        style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
                                                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                {currentQuestionData && (
                                                                    <>
                                                                        <div class="img-full-sec">
                                                                            <img src={currentQuestionData.img.src} />

                                                                        </div>
                                                                        <h3 className='quiz-quest'>{currentQuestionData.question}</h3>
                                                                        <h3 className='question-para'>{currentQuestionData.passage}</h3>
                                                                        <ul>
                                                                            {currentQuestionData?.options?.map((item, index) => (
                                                                                <div key={index}>
                                                                                    <li>
                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                id={`radio-${index}`}
                                                                                                onClick={(e) => {
                                                                                                    updateAnswer(e,
                                                                                                        currentQuestionData.question, item.option)
                                                                                                }}
                                                                                                type="radio"
                                                                                                name="option"
                                                                                                value={item.correct}
                                                                                                class="form-check-input"
                                                                                            />
                                                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                                                {item.option}
                                                                                            </label>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                            ))}
                                                                        </ul>
                                                                        {currentQuestion + 1 < questions.length && currentQuestionData.checked ? (
                                                                            <a type="button" onClick={nextQuestion}>
                                                                                Next
                                                                            </a>
                                                                        ) : ''}
                                                                        {currentQuestion + 1 === questions.length && currentQuestionData.checked && (
                                                                            <a type="button" onClick={() => { getResults(currentQuestionData.question); setCount(0) }}>
                                                                                Get Results
                                                                            </a>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <img src={quizvictor} />
                                        </form>
                                    </div>
                                </>
                                : ''
                        }
                    </>
                    :
                    (
                        show ?
                            <div class="subscribe-secc">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="header-heading inner">
                                                <h2>Class Test</h2>

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12">
                                            <div class="quiz-content congratulation-sec">
                                                <img src={winner} />
                                                <h2>Congratulations</h2>
                                                <h5>You scored</h5>
                                                {/* <h2>{((correct / questions.length) * 100).toFixed()}%</h2> */}
                                                <h2>{`${correct} / ${questions.length}`}</h2>


                                                <p>correct answers</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <img src={quizvictor} />
                            </div>
                            : ''
                    )
            }
        </div>
    );
};

export default Questionaire;