import React, { useState, useEffect } from 'react'
import EventDetail from './EventDetail';
import { get_events, get_student_question_list, get_course_by_grade } from '../../apis/Dashboard'
import { Pagination, Pagination1 } from './pagination';
import { HTTPURL } from '../../constants/Matcher';
const Dashboard = () => {
    const [open, setOpen] = useState(false);
    const [id,setId]=useState('');
    const [events, setEvents] = useState([]);
    const [course, setCourse] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const studentType = localStorage.getItem('studentType')
    
    //pagination 
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage1, setCurrentPage1] = useState(1);
    const itemsPerPage = 5;
    const totalItems = questionList?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const totalItems1 = events?.length;
    const totalPages1 = Math.ceil(totalItems1 / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const indexOfLastItem1 = currentPage1 * itemsPerPage;
    const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage;
    const currentItems = questionList?.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems1 = events?.slice(indexOfFirstItem1, indexOfLastItem1);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handlePageChange1 = (pageNumber) => {
        setCurrentPage1(pageNumber);
    };
    useEffect(() => {
        async function get_data() {
            const event = await get_events();
            const question_list = await get_student_question_list();
            const course = await get_course_by_grade();
            setEvents(event?.data);
            setQuestionList(question_list?.data);
            setCourse(course?.data)
        }
        get_data();
    }, []);
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = today.getMonth();
    let yyyy = today.getFullYear();
    today = dd + mm + yyyy;
    return (
        <div className='dashboard_n'>
            <div className="row">
                <div className="col-xl-7 ">
                    {studentType == 1 ?
                        <div className="row">
                            <div className="col-xl-12 col-md-12 new_test">
                                {/* <h4 className="sap_lg_heading">New e-Test</h4> */}
                            </div>
                            {questionList?.slice(0, 2).map((elem) => {
                                return (
                                    <div className="col-xl-6 col-md-6">
                                        {/* {elem?.end_time > today ?
                                            <div className="test-card">
                                                <img src="images/test1.png" />
                                                <div className="test-card-content">
                                                    <h3>{elem?.question_name}</h3>
                                                    <a href="#">for Y5 & 6 (10 Sets)</a>
                                                    <a href="#">{elem?.test_type}</a>
                                                </div>
                                                <div className="test-action free">
                                            <a href="#">Free Entrance Tests</a>
                                        </div>
                                            </div> : ''
                                        } */}

                                    </div>
                                )
                            })}
                        </div> : ''}
                        {/* <div className="row">
                                <div className="event">
                                    <h2 className="All e-Test list">Upcoming Events</h2>
                                </div>
                                <div className="col-12">
                                    <div className="sch_event">
                                        {
                                            currentItems1 && currentItems1?.map((e) => {
                                                return (
                                                    <>
                                                        <div className="sch_event_main">
                                                            <div className="sch_event_inner">
                                                                <div className="july_num">
                                                                    <h2>{e?.date}</h2>
                                                                    <h4>{e?.month}</h4>
                                                                </div>
                                                                <div className="evnt_head">
                                                                    <h2>{e?.title}</h2>
                                                                    <h4>{e?.date_time}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="sch_view">
                                                                <button onClick={() =>( setOpen(true),setId(e?.id))}>View</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                )
                                            })
                                        }
                                        <div className="pagin">
                                            <Pagination
                                                currentPage={currentPage1}
                                                totalPages={totalPages1}
                                                onPageChange={handlePageChange1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                </div>
                

                {open ? <EventDetail open={open} setOpen={setOpen} id={id} /> : null}
                <div className="col-xl-12 col-md-12 new_test">
                    <h4 className="sap_lg_heading">Courses</h4>
                </div>
                <div className='row'>
                        {course?.map((course) => (
                            <div className='col-md-4 align-item-center course-sec'>  
                                <ul>
                                    <li>
                                        <div className='test-card'>
                                            <div className="row">
                                                <div className="col-6">
                                                    <img src={`${HTTPURL}uploads/${course.image}`} height={200} width={200} />
                                                </div>
                                                <div className="col-6 course_type">
                                                    <div className="cag-title summer">{course.term_name}</div>
                                                    <div className="cag-title">Name: {course.name}</div>
                                                    <div className="cag-title">Type: {course.course_type}</div>
                                                    <div className="cag-title">Price: ${course.price}</div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </li>   
                                </ul>
                            </div>

                        ))}
                </div>
            

                {/* <div className="table-responsive tbl_bg">
                        <table className="table">
                            <thead>
                                <tr>
                                <th scope="col">No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    course && course?.map((elem,i) => {
                                        console.log(elem.time)
                                        return (
                                            <tr>
                                                <td>{i+1}</td>
                                                <td>{elem?.name}</td>
                                                <td>AUD {elem?.price} </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div> */}
            </div>
        </div>
    )
}
export default Dashboard;
