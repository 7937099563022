import React from "react";
import { useState, useEffect } from "react";
import { Summative_assessment_List } from "../../../apis/E_S_Assesment";
import Pagination from "../../Pagination";
import {formatDate} from '../../../utils/Utils';
const AssesmentList = () => {
  const [itemsPerPage] = useState(5);
  const [summativeList, setSummativeList] = useState([]);
  const [currentPages, setCurrentPages] = useState(() => {
    if (summativeList?.length > 0) {
      return summativeList?.reduce((acc, ele) => {
        acc[ele.courseid] = 1;
        return acc;
      }, {});
    }
    return {};
  });
  useEffect(() => {
    async function get_data() {
      const worklist = await Summative_assessment_List("9");
      setSummativeList(worklist?.data);
    }
    get_data();
  }, []);

  const onPageChange = (page, courseid, termid) => {
    const pageKey = `${courseid}-${termid}`;
    setCurrentPages((prevPages) => ({
      ...prevPages,
      [pageKey]: page,
    }));
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="table_head">
          <h2 className="">Summative Assessment</h2>
        </div>
        {summativeList?.length > 0 &&
          summativeList?.map((ele) => {
            const currentPageKey = `${ele.year}-${ele.grade_name}-${ele.term_name}`;
            const currentPage = currentPages[currentPageKey] || 1;
            const itemsPerPage = 5;
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;

            return (
              <div key={`${ele.year}-${ele.grade_name}-${ele.term_name}`}>
                <h5>
                  {ele.year}  {`Year ${ele.grade_name}`} - {ele.term_name}
                </h5>
                <div className="table-responsive tbl_bg lectures">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "30%" }}>
                          Date
                        </th>
                        <th scope="col" style={{ width: "40%" }}>
                          Title
                        </th>
                        <th scope="col" style={{ width: "30%" }}>
                          Test
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ele?.assessments?.length > 0 ? (
                        ele?.assessments
                          ?.slice(startIndex, endIndex)
                          ?.map((assessment, idx) => {
                            const title = `${assessment?.titles?.year} Year ${assessment?.titles?.grade_name} ${assessment?.titles?.term} ${assessment?.titles?.type}`;
                            return (
                              <tr
                                key={`${assessment?.tstm_guid}-${idx}-${ele?.term_name}`}
                              >
                                <td>{formatDate(assessment?.Date)}</td>
                                <td>{title}</td>
                                <td>
                                  <a
                                    href={assessment?.tstm_guid}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="take-test"
                                  >
                                   Take Test
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan="3">No Assessments Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagin">
                  <Pagination
                    onPageChange={(page) =>
                      onPageChange(
                        page,
                        ele.year,
                        ele.grade_name,
                        ele.term_name
                      )
                    }
                    totalPages={Math.ceil(
                      ele.assessments.length / itemsPerPage
                    )}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            );
          })}

        <div className="tbl_content">
          <ol type="1">
            {/* <li>* If you are not enrolled in CS Online School, please <span className="join">Join Membership first</span> and then take the test.</li> */}
            <li>
              You can take the test ONLY ONCE during the available period.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default AssesmentList;
