import React, { useState, useEffect } from "react";
import {
  getlectureData,
  enroll_student_payment,
} from "../../../apis/My-e-Test/Classes";
import { get_branch } from "../../../apis/Common";
import Pagination from "../../Pagination";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const Classes = () => {
  const [open, setOpen] = useState(false);
  const [lectureData, setLectureData] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [enrollStatus, setEnrollStatus] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const grade = localStorage.getItem("grade");
  const userID = localStorage.getItem("studentId");
  const [currentPages, setCurrentPages] = useState(() => {
    if (lectureData?.lectures?.length > 0) {
      return lectureData.lectures.reduce((acc, ele) => {
        acc[ele.courseid] = 1;
        return acc;
      }, {});
    }
    return {};
  });

  useEffect(() => {
    async function get_data() {
      const lecturlist = await getlectureData(currentPages);
      const branchData = await get_branch();
      setLectureData(lecturlist?.data || []);
      setBranch(branchData?.data || []);
    }
    get_data();
  }, [currentPages]);
  const submitData = async (e) => {
    e.preventDefault();
    const formData = {
      grade_id: grade,
      user_id: userID,
      total_price: 10,
      branch_id: branchId,
      subject_id: 0,
      payment_method: paymentMethod,
    };
    if (enrollStatus) {
      const res = await enroll_student_payment(formData);
      if (res?.status) {
        setOpen(false);
        localStorage.setItem("studentType", 1);
      }
    } else {
      toast.error("Enrolled status is required");
    }
  };

  const onPageChange = (page, courseid, termid) => {
    const pageKey = `${courseid}-${termid}`;
    setCurrentPages((prevPages) => ({
      ...prevPages,
      [pageKey]: page,
    }));
  };
  const currentData = lectureData;
  const studentType = localStorage.getItem("studentType");
  const [itemsPerPage] = useState(5);
  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="table_head">
            <h2>Lectures</h2>
          </div>
          {currentData?.lectures?.length > 0 &&
            currentData?.lectures?.map((ele) => {
              const currentPageKey = `${ele.courseid}-${ele.termid}`;
              const currentPage = currentPages[currentPageKey] || 1;
              const startIndex = (currentPage - 1) * itemsPerPage;
              const endIndex = startIndex + itemsPerPage;
              return (
                <div key={`${ele.courseid}-${ele.termid}`}>
                  <h3>{ele?.course_name}</h3>
                  <h5>
                    {ele?.year} {ele?.grade_names} - {ele?.term_name}
                  </h5>
                  <div className="table-responsive tbl_bg lectures" >
                    <table className="table" border="1">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "15%" }}>
                            Unit #
                          </th>
                          {ele?.subjectdata?.map((sub) => (
                            <th
                              key={`${sub.subject_id}-${ele.termid}`}
                              scope="col"
                              style={{ width: "15%" }}
                              className="text-capitalize"
                            >
                              {sub?.subject}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {ele?.units?.length > 0 ? (
                          ele?.units
                            ?.slice(startIndex, endIndex)
                            ?.map((unit, unitIndex) => {
                              return (
                                <tr key={`${unit?.unit_id}-${unitIndex}`}>
                                  <td style={{ cursor: "pointer" }}>
                                    {unit?.name || "-"}
                                  </td>
                                  {ele?.subjectdata?.map((subject) => {
                                    const subjectData = unit.subjects.find(
                                      (sub) =>
                                        sub.subject_id === subject.subject_id
                                    );
                                    const unitDataForSubject =
                                      subjectData?.units_json_data || [];
                                    return (
                                      <td
                                        key={`${unit.unit_id}-${subject.subject_id}`}
                                      >
                                        {unitDataForSubject.length > 0
                                          ? unitDataForSubject
                                              ?.filter(
                                                (jsonData) =>
                                                  jsonData?.abbreviation &&
                                                  jsonData?.file_path
                                              )
                                              ?.map((jsonData, idx) => (
                                                <a
                                                  key={`${jsonData.file_path}-${idx}`}
                                                  href={jsonData.file_path}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    marginRight: 5,
                                                    color: "red",
                                                  }}
                                                >
                                                  {jsonData.abbreviation}
                                                  {idx <
                                                    unitDataForSubject.length -
                                                      1 && " | "}
                                                </a>
                                              ))
                                          : "-"}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan={ele?.subjectdata?.length + 1}>
                              No Data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination */}
                  <div className="pagin">
                    <Pagination
                      onPageChange={(page) =>
                        onPageChange(page, ele.courseid, ele.termid)
                      }
                      totalPages={Math.ceil(ele.data?.length / itemsPerPage)}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enroll Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitData}>
            <div className="form-group">
              <label>Branch:</label>
              <select
                value={branchId}
                onChange={(e) => setBranchId(e.target.value)}
              >
                {branch.map((option) => (
                  <option key={option.branch_id} value={option.branch_id}>
                    {option.branch_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Payment Method:</label>
              <select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="1">Credit Card</option>
                <option value="2">PayPal</option>
              </select>
            </div>
            <div className="form-group">
              <label>Enroll Status:</label>
              <input
                type="checkbox"
                checked={enrollStatus}
                onChange={() => setEnrollStatus(!enrollStatus)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Classes;
