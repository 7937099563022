import React from "react";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { events_edit } from "../../apis/Dashboard";
const EventDetail = ({ open, setOpen, id }) => {
  const [evntDetail, setEvntDetail] = useState([]);
  useEffect(() => {
    async function get_data() {
      const data = await events_edit(id);
      setEvntDetail(data?.data[0]);
    }
    get_data();
  }, []);
  return (
    <div>
  <Modal
    show={open}
    onHide={() => setOpen(false)}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="event-popup"
  >
    <Modal.Header closeButton>
      <Modal.Title className="modal-title-centered">
        {evntDetail?.title} ({evntDetail?.event_type})
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="modal-body view_modal_cont">

      <h4>Venue Name</h4>
        <p>
          {evntDetail?.venue_name}<br />
          {/* (Offline) {evntDetail?.address} */}
        </p>

        <h4>Description</h4>
        <p>{evntDetail?.description}</p>

        <h4>Event Date and Time</h4>
        <p>
          <b>Date:</b> {evntDetail?.date}<br />
          <b>Start Time:</b> {evntDetail?.break_start}<br />
          <b>End Time:</b> {evntDetail?.break_end}
        </p>

      </div>
    </Modal.Body>
  </Modal>
</div>

  );
};

export default EventDetail;

// const EventDetail = ({ids})  =>{
//     console.log('ids',ids);
//     const [evntDetail,setEvntDetail]=useState([]);
//     const [eventData,setEventData] = useState([]);
//     const id = useParams();
//     useEffect(() => {
//         async function get_data() {
//             const data = await events_edit(id);
//             setEvntDetail(data?.data[0]);
//             if (data?.data[0]?.question_data) {
//                 setEventData(JSON.parse(data?.data[0]?.question_data))
//             }
//         }
//         get_data();
//     }, []);
//     return (
//         <div>
//             <div className="right-nav" id="dash-event">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h4 className="modal-title">Event Detail</h4>
//                     </div>
//                     <div className="modal-body">
//                         <div className="tab-content" id="pills-tabContent">
//                             <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
//                                 <div className="form-horizontal">
//                                     <div className="row">
//                                         <div className="col-md-3">
//                                             <div className="form-group">
//                                                 <label>event_type</label>
//                                                 <p>{evntDetail?.grade_id ? `grade ${evntDetail?.grade_id}` : '-'}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="form-group">
//                                                 <label>Term</label>
//                                                 <p>{evntDetail?.term_id ? `Term ${evntDetail?.term_id}` : '-'}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="form-group">
//                                                 <label>Course Type</label>
//                                                 <p>{evntDetail?.category_name ? ` ${evntDetail?.category_name}` : '-'}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="form-group">
//                                                 <label>Subject</label>
//                                                 <p>{evntDetail?.subject ? evntDetail?.subject : '-'}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <hr />
//             </div>
//         </div>
//     )
// }

// export default EventDetail;
