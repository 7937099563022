import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Assesment_question_Detail } from '../../../apis/E_S_Assesment';
const AssesmentDetail = () => {
    const [questionList, setQuestionList] = useState([]);
    const [answerData, setAnswerData] = useState([]);
    const id = useParams()
    useEffect(() => {
        async function get_data() {
            const question = await Assesment_question_Detail(id?.id);
            setQuestionList(question?.data);
            setAnswerData(JSON.parse(question?.data[0]?.answer_data))
        }
        get_data();
    }, []);
    return (
        <div className="row">
            <div className="col-12">
                <div className="profile_shade">
                    <div className="grade_content profile_title">
                        <h2>Your Qusetions Answer</h2>

                    </div>
                    <hr className="m-0" />
                    <div className="row">
                        {questionList && questionList?.map((elem) => {
                            return (
                                <div className="col-12">
                                    <div className="profile_cont">
                                    <div className="profile_inner_cont">
                                            <h2>Subject</h2>
                                            <h4>{elem.subject}</h4>
                                        </div>
                                        <div className="profile_main">
                                            <div className="profile_inner_cont men_add">
                                                <h2>Question Name</h2>
                                                <h4>{elem.question_name}</h4>

                                            </div>
                                        </div>
                                        <div className="profile_inner_cont">
                                            <h2>Correct Answers</h2>
                                            <h4>{elem.correct}</h4>
                                        </div>
                                        <div className="profile_inner_cont">
                                            <h2>Time</h2>
                                            <h4>{elem.created_at}</h4>
                                        </div>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="profile_add">
                                        <h2>Your Answers </h2>
                                    </div>
                                    <hr className="m-0" />
                                    {answerData && answerData?.map((elem) => {
                                        return (
                                            <div className="profile_cont questionair-sec">
                                                <div className="profile_inner_cont profile_inner_add">
                                                    <h2>Question</h2>
                                                    <h4>{elem.question}</h4>
                                                </div>
                                                <div className="profile_inner_cont">
                                                    <h2>Answer</h2>
                                                    <h4>{elem.answer}</h4>
                                                </div>
                                            </div>
                                        )

                                    })}

                                    
                                </div>
                            )

                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssesmentDetail;
